<template>
  <v-container fluid>
    <!-- DIALOG USUARIO -->
    <v-dialog width="600" v-model="dialogEditExport">
      <v-card>
        <v-card-title>Escolha campos para relatório de usuários</v-card-title>
        <v-card-text>

          <v-checkbox dense v-for="(att, index) in camposUsuario" :key="index" :label="att" :value="att"
            v-model="selectedFieldsUsuario"></v-checkbox>
          <div style="width:100%;display: flex;justify-content: flex-end;">
            <v-btn color="accent" @click="gerarXls('usuario')">GERAR RELATÓRIO</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- DIALOG DENUNCIA -->
    <v-dialog width="600" v-model="dialogEditExportDenuncia">
      <v-card>
        <v-card-title>Escolha campos para relatório de denúncias</v-card-title>
        <v-card-text>
          <v-checkbox dense v-for="(att, index) in fieldDenuncia" :key="index" :label="att.name" :value="att.value"
            v-model="camposDenuncia"></v-checkbox>
          <div style="width:100%;display: flex;justify-content: flex-end;">
            <v-btn color="accent" @click="gerarXls('denuncias')">GERAR RELATÓRIO</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div style="display: flex; justify-content: center;">
      <v-btn class="mt-4" color="accent" @click="dialogEditExportDenuncia = true"> Relatório Denuncias </v-btn>
    </div>
    <div style="display: flex; justify-content: center;">
      <v-btn color="accent" @click="dialogEditExport = true" class="mt-6" style="display: block;"> Relatório Usuarios
      </v-btn>
    </div>
    <v-btn style="position: absolute; bottom: 16px" color="accent" @click="$router.go(-1)">voltar</v-btn>
  </v-container>
</template>
<script>

import * as XLSX from 'xlsx';

import FileSaver from 'file-saver';

export default {
  name: "Exportar",
  props: {
    denuncias: Array,
    usuarios: Array,
  },
  data() {
    return {
      dialogEditExport: false,
      dialogEditExportDenuncia: false,
      camposUsuario: ["identificação", "usuario", "email", "telefone", "pertencente a", "cidade", "estado", "registrado em"],
      selectedFieldsUsuario: ["identificação", "usuario", "email", "telefone", "pertencente a", "cidade", "estado", "registrado em"],
      camposDenuncia: ["id", "relato", "lat", "lng", "categoria", "impactos", "elementos", "publicado", "createdAt", "updatedAt", "users_permissions_user.username", "fotos"],
      fieldDenuncia: [
        {
          name: "código",
          value: "id"
        },
        {
          name: "relato",
          value: "relato"
        },
        {
          name: "latitude",
          value: "lat"
        },
        {
          name: "longitude",
          value: "lng"
        },
        {
          name: "categoria",
          value: "categoria"
        },
        {
          name: "impactos",
          value: "impactos"
        },
        {
          name: "elementos",
          value: "elementos"
        },
        {
          name: "publicado",
          value: "publicado"
        },
        {
          name: "criado em",
          value: "createdAt"
        },
        {
          name: "atualizado em",
          value: "updatedAt"
        },
        {
          name: "usuario denunciante",
          value: "users_permissions_user.username"
        },
        {
          name: "fotos",
          value: "fotos"
        },
      ]

    }
  },
  computed: {
    camposDenuncia2() {
      return Object.keys(this.denuncias[0])
    }
  },
  methods: {
    gerarXls(tipo) {
      let objsModificado = {};
      if (tipo == 'usuario') {
        objsModificado = this.usuarios.map((obj) => {
          const filteredObj = {};

          // FILTRA CAMPOS SELECIONADOS
          Object.keys(obj).forEach((key) => {
            if (this.selectedFieldsUsuario.includes(key)) {
              filteredObj[key] = obj[key];
            }
          });

          if (this.selectedFieldsUsuario.includes('identificação'))
            filteredObj.id = obj.id

          if (this.selectedFieldsUsuario.includes('pertencente a'))
            filteredObj.associação = obj.associacao

          if (this.selectedFieldsUsuario.includes('registrado em'))
            filteredObj.registradoEm = obj.createdAt


          return filteredObj;
        });
      } else if (tipo == 'denuncias') {
        objsModificado = this.denuncias.map((denuncia) => {
          const filteredObj = {};

          // FILTRA CAMPOS SELECIONADOS

          Object.keys(denuncia).forEach((key) => {
            if (this.camposDenuncia.includes(key)) {
              filteredObj[key] = denuncia[key]
            }
          });


          if (this.camposDenuncia.includes("fotos")) {
            if (denuncia.photo) {

              if (denuncia.photo.length > 1) {
                let objArray = [];
                denuncia.photo.forEach((foto) => {
                  objArray.push(foto.url)
                })
                filteredObj.fotos = objArray.join(';')
              } else {
                filteredObj.fotos = denuncia.photo[0].url
              }
            } else {
              filteredObj.fotos = ''
            }
          }

          return filteredObj;
        });
      }

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(objsModificado);

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Denuncias');

      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, `${tipo}.xlsx`);
    }
  }
}
</script>